import React from 'react';

const About = () => {
  return (
    <section className="about-section p-8 md:p-16 rounded-lg ">
      <h2 className="text-3xl font-bold mb-6 text-[#009CD9] text-center">About Us</h2>
      <div className="about-content text-center">
        <p className="mb-2 m-3 gap-3">Welcome to Flutteropia, your gateway to the world of Flutter development! At Flutteropia, we're more than just an e-learning platform; we're a community of passionate learners and educators dedicated to empowering individuals to harness the power of Flutter for building beautiful, high-performance applications.Our mission is simple: to provide accessible, engaging, and comprehensive resources that enable learners of all levels to master Flutter development.Whether you're an aspiring developer looking to kickstart your career, an experienced professional seeking to enhance your skills, or an enthusiast eager to explore the latest trends in app development, Flutteropia has something for everyone.Join us on this exciting journey as we explore new frontiers in mobile app development and unlock endless possibilities with Flutter.</p></div></section>
  );
}

export default About;
