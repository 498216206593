// HeroSection.js
import React from 'react';

const Hero = () => {
  return (
    <section className="hero flex items-center py-40 "> {/* Added padding */}
      <div className="hero-content px-24 font-hubballi font-normal tracking-wide mr-10 scale-110"> {/* Added margin-right for spacing */}
        <h3 className="text-left text-2xl md:text-3xl lg:text-4xl mb-2">EXPLORE NEW</h3> {/* Adjusted font size */}
        <h1 className="text-left text-3xl md:text-4xl lg:text-5xl mb-4">SKILLS WITH US</h1> {/* Adjusted font size */}
        <p className="text-left text-lg md:text-xl lg:text-2xl mb-8">WE’RE ON A MISSION TO DELIVER ENGAGING, CURATED COURSES AT NO COST FOR STUDENTS</p> {/* Adjusted font size */}
        <button className="bg-[#009CD9] text-white border border-black font-bold py-2 px-4 rounded">Get Started</button>
        <button className=" text-black border border-black font-bold py-2 px-8 ml-8 rounded">Learn More</button>
      </div>
      <div className="hero-image scale-80 -mt-[185px] -ml-[500px]">
        <img src="/flugo.png" alt="Flutteropia Logo"  width={1500} height={150} /> 
      </div>
    </section>
  );
}

export default Hero;
