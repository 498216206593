import React, { useState } from 'react';

const Faq = () => {
  const faqs = [
    {
      question: "What is Flutteropia?",
      answer: "Flutteropia is an online platform dedicated to providing accessible and engaging resources for learning Flutter development.",
    },
    {
      question: "Who can use Flutteropia?",
      answer: "Flutteropia is open to anyone interested in learning Flutter, regardless of their skill level or background.",
    },
    {
      question: "Are the courses free?",
      answer: "Yes, all courses and resources on Flutteropia are available for free to ensure equal access to education for everyone.",
    },
    {
      question: "Do I need any prior experience to start learning on Flutteropia?",
      answer: "No prior experience is required to start learning on Flutteropia. Our courses cater to learners of all levels, from beginners to advanced developers.",
    },
    {
      question: "How can I enroll in a course?",
      answer: "Enrolling in a course on Flutteropia is easy. Simply browse our course catalog, select the course you're interested in, and click on the 'Enroll' button to get started.",
    },
    {
      question: "Are the courses self-paced?",
      answer: "Yes, most of the courses on Flutteropia are self-paced, allowing you to learn at your own convenience and schedule.",
    },
    // Add more FAQ entries as needed
  ];

  // State to keep track of the currently expanded FAQ item
  const [expandedIndex, setExpandedIndex] = useState(null);

  // Function to toggle the expanded state of an FAQ item
  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      // Collapse if clicked on the currently expanded item
      setExpandedIndex(null);
    } else {
      // Expand the clicked item
      setExpandedIndex(index);
    }
  };

  return (
    <section className="faq-section p-8">
      <h2 className="text-3xl font-bold mb-6 text-black text-center"><span className='text-[#009CD9]'>F</span>requently <span className='text-[#009CD9]'>A</span>sked <span className='text-[#009CD9]'>Q</span>uestions</h2>
      <div className="faq-list p-8">
        {faqs.map((faq, index) => (
          <div key={index} className=" p-6 faq-item">
            <button
              className="flex items-center justify-between w-full py-4 px-6 bg-white text-left rounded-lg shadow-md focus:outline-none"
              onClick={() => toggleExpand(index)}
            >
              <span className="font-semibold ">{faq.question}</span>
              <svg
                className={`w-6 h-6 transition-transform duration-300 transform ${
                  expandedIndex === index ? 'rotate-180' : ''
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 111.414-1.414L10 10.586l2.293-2.293a1 1 0 111.414 1.414l-3 3A1 1 0 0110 12z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {expandedIndex === index && (
              <div className="faq-answer mt-4 bg-[#009CD9] bg-opacity-75 text-white p-8 rounded-b-lg">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Faq;
