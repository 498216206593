// src/routes/LandingPage.js
import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import CardsSection from '../components/CardsSection';
import Faq from '../components/Faq';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function LandingPage() {
  return (
    <div className="LandingPage">
      <Navbar />
      <Hero /><section id="about">
      <About id="about"/>
</section>
      <CardsSection />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
}

export default LandingPage;
