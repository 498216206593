import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'; // Import HashLink
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase'; // Assuming Firebase setup in a separate file

const Navbar = () => {
  const handleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful login
        console.log('User signed in:', result.user);
      })
      .catch((error) => {
        // Handle errors
        console.error('Login error:', error);
      });
  };

  const handleSignup = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful signup
        console.log('User signed up:', result.user);
      })
      .catch((error) => {
        // Handle errors
        console.error('Signup error:', error);
      });
  };

  return (
    <nav className="bg-white bg-opacity-35 flex items-center justify-between py-4 px-6 text-lg font-hubballi text-black">
      <Link to="/">
        <div className="flex items-center -ml-10 -mt-4">
          <img src="/logo.svg" alt="Logo" width={300} height={150} />
        </div>
      </Link>
      <ul className="flex items-center space-x-32 flex-grow -pl-10 justify-center">
        <li>
          <Link smooth to="/courses">
            <button className="bg-transparent py-2 px-4 rounded font-medium text-black text-xl tracking-wider">
              COURSES
            </button>
          </Link>
        </li>
        <li>
        <Link smooth to="/#about">
  <button className="bg-transparent py-2 px-4 rounded font-medium text-black text-xl tracking-wider">
    ABOUT
  </button>
</Link>

        </li>
      </ul>
      <div className="flex items-center -pl-6 space-x-4">
        <button className="bg-transparent py-2 px-4 rounded font-medium text-black" onClick={handleLogin}>
          LOGIN
        </button>
        <button className="border border-black rounded px-4 py-2 font-medium text-black" onClick={handleSignup}>
          SIGNUP
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
