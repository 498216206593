import React from 'react';

const CardsSection = () => {
  return (
    <section className="container mx-auto py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <Card
          title="Easy to Learn"
          description="Our platform provides beginner-friendly tutorials and resources, making it easy for anyone to learn."
          icon="📘"
          color="bg-blue-400"
        />
        <Card
          title="Community Support"
          description="Join our vibrant community of learners and experts who are always ready to help each other out."
          icon="👥"
          color="bg-green-400"
        />
        <Card
          title="Regular Updates"
          description="Stay up-to-date with the latest trends and updates in Flutter development through our regular content updates."
          icon="🚀"
          color="bg-yellow-400"
        />
        <Card
          title="Free Access"
          description="All our courses and resources are available for free, ensuring equal access to education for everyone."
          icon="💰"
          color="bg-purple-400"
        />
      </div>
    </section>
  );
}

const Card = ({ title, description, icon, color }) => {
  return (
    <div className={`rounded-lg p-6 shadow-md hover:shadow-xl transition duration-300 ${color}`}>
      <div className="flex items-center justify-center rounded-full h-16 w-16 mb-6 bg-white text-black">
        <span>{icon}</span>
      </div>
      <h3 className="text-xl font-semibold mb-4 text-white">{title}</h3>
      <p className="text-base text-white">{description}</p>
    </div>
  );
}

export default CardsSection;
