// src/routes/CoursesPage.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { flutterCourses } from '../data/CourseData'; // Importing course data

const CoursesPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');

  // Filter courses based on search and filter terms
  const filteredCourses = flutterCourses.filter(course => {
    return course.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
           (filterTerm === '' || course.level === filterTerm);
  });

  return (
    <div className="min-h-screen font-poppins">
      <Navbar />
      <div className="bg-[#009CD9]  bg-opacity-50">
        <div className="container mx-auto px-4 py-8">
          <h2 className="text-4xl font-bold mb-8 text-center text-white ">Explore Advanced Flutter Courses</h2>

          {/* Search bar */}
          <div className="bg-white mb-6 flex items-center rounded-lg">
            <input
              type="text"
              className="border border-gray-300 px-4 py-2 w-full rounded-lg focus:outline-none focus:border-blue-500 transition duration-300"
              placeholder="Search courses..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="bg-[#009CD9] hover:bg-opacity-100 bg-opacity-75 text-white px-4 py-2 ml-2 mr-1 rounded-md transition duration-300"
              onClick={() => setSearchTerm('')}
            >
              Clear
            </button>
          </div>

          {/* Filter by level */}
          <div className="bg-white mb-6">
            <select
              className="border border-gray-300 px-4 py-2 rounded-md w-full focus:outline-none focus:border-blue-500 transition duration-300"
              value={filterTerm}
              onChange={(e) => setFilterTerm(e.target.value)}
            >
              <option value="">Filter by level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>

          {/* Courses list */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredCourses.map(course => (
              <div key={course.id} className="bg-white border border-gray-300 rounded-lg overflow-hidden hover:shadow-xl transition duration-300">
                <div className="relative">
                  <img
                    src={course.image}
                    alt={course.title}
                    className="h-60 w-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black opacity-30 hover:opacity-0 transition duration-300"></div>
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition duration-300">
                    <Link
                      to={`/courses/${course.id}`}
                      className="text-white text-lg font-semibold bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-md"
                    >
                      View Course
                    </Link>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{course.title}</h3>
                  <div className="flex items-center mb-2">
                    <img
                      src={course.avatar}
                      alt={`${course.instructor}'s avatar`}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    <p className="text-gray-600">{course.instructor}</p>
                  </div>
                  <p className="text-gray-600 mb-2">Level: {course.level}</p>
                  <div className="flex flex-wrap">
                    {course.tags.map((tag, index) => (
                      <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md text-sm mr-2 mb-2">{tag}</span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesPage;
