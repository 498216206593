// src/routes/CoursePage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { flutterCourses } from '../data/CourseData'; // Importing course data

const CoursePage = () => {
  let { courseId } = useParams(); // Extracting courseId from URL params

  // Finding the course details based on courseId
  const course = flutterCourses.find(course => course.id === parseInt(courseId));

  // State to track scroll progress
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showCompleteButton, setShowCompleteButton] = useState(false);

  // Calculate scroll progress based on page height and scroll position
  const handleScroll = () => {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    const progress = (scrolled / scrollableHeight) * 100;
    setScrollProgress(progress);

    // Show complete button when scrolled to the bottom
    if (progress >= 100) {
      setShowCompleteButton(true);
    } else {
      setShowCompleteButton(false);
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // If course is not found, handle accordingly
  if (!course) {
    return <div className="min-h-screen flex items-center justify-center text-red-600 text-2xl">Course not found!</div>;
  }

  return (
    <div className="min-h-screen font-poppins">
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img src={course.image} alt={course.title} className="w-full h-64 object-cover" />
          <div className="p-6">
            <h2 className="text-3xl font-semibold mb-4">{course.title}</h2>
            <div className="flex items-center mb-4">
              <img src={course.avatar} alt={`${course.instructor}'s avatar`} className="w-10 h-10 rounded-full mr-2" />
              <p className="text-gray-600">{course.instructor}</p>
            </div>
            <p className="text-gray-600 mb-4">Level: {course.level}</p>
            <div className="flex flex-wrap mb-4">
              {course.tags.map((tag, index) => (
                <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md text-sm mr-2 mb-2">{tag}</span>
              ))}
            </div>
            <p className="text-gray-700 mb-8">{course.description}</p>

            {/* Popup for completion */}
            {showCompleteButton && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-6 rounded-lg max-w-md text-center">
                  <h3 className="text-xl font-semibold mb-4">Course Completed!</h3>
                  <div className="mb-4">
                    <div className="bg-gray-300 h-6 rounded-full overflow-hidden">
                      <div className="bg-[#009CD9] h-full" style={{ width: `${scrollProgress}%` }}></div>
                    </div>
                  </div>
                  <Link to="/completion">
                    <button className="bg-[#009CD9] hover:bg-opacity-100 bg-opacity- text-white px-6 py-2 rounded-md transition duration-300">
                      Completed
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePage;
