// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; // Import necessary Firebase auth modules


// Your Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAcZRtjxb4qP7CwLN1JZiZ7SVqTk_G-3eY",
  authDomain: "flutteropia.firebaseapp.com",
  projectId: "flutteropia",
  storageBucket: "flutteropia.appspot.com",
  messagingSenderId: "904552582383",
  appId: "1:904552582383:web:3753a1b436e83be0eb9f1a",
  measurementId: "G-D4YSF6D83S"
};

// Initialize Firebase app if it hasn't been initialized yet
const app = initializeApp(firebaseConfig);

// Example login function
const handleLogin = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      // Handle successful login
      console.log('User signed in:', result.user);
    })
    .catch((error) => {
      // Handle errors
      console.error('Login error:', error);
    });
};

ReactDOM.render(
  <React.StrictMode>
      <App handleLogin={handleLogin} /> {/* Pass handleLogin function as prop */}
  </React.StrictMode>,
  document.getElementById('root')
);
