// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAcZRtjxb4qP7CwLN1JZiZ7SVqTk_G-3eY",
    authDomain: "flutteropia.firebaseapp.com",
    projectId: "flutteropia",
    storageBucket: "flutteropia.appspot.com",
    messagingSenderId: "904552582383",
    appId: "1:904552582383:web:3753a1b436e83be0eb9f1a",
    measurementId: "G-D4YSF6D83S"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
