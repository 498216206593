import React from "react";

const Contact = () => {
  const handleSubmit = () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;

    const subject = "Collaboration Inquiry";
    const body = `Hello,\n\nMy name is ${name} and my email is ${email}.\n\nMessage: ${message}\n\n`;

    const mailtoLink = `mailto:afnnafsal@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="flex items-center justify-center min-h-screen md:p-4 p-0">
      <div
        
        className="text-center p-4   shadow-lg  rounded-xl md:p-8 text-gray-500  border-2  backdrop-blur-md bg-opacity-10 "
      >
      
        <div id="contactUs" className="mb-8">
        <h3 className="text-center text-3xl pt-5 pb-5  text-gray-500 font-poppins font-extrabold md:text-3xl md:text-center ">
            CONTACT <span className="text-[#009CD9] "> ME</span>
          </h3>
          <p className="text-center text-md pt-2 leading-8  md:text-lg mx-auto text-gray-500 ">
          <span className="text-[#009CD9]  text-lg md:text-xl"> I am</span> happy to assist you with any queries you may have.
          </p>
        </div>


        <div className="text-center">
          <div className="mb-4">
            <input
              id="name"
              type="text"
              placeholder="Name"
              className="w-full h-12 border cursor-none bg-transparent text-gray-500  rounded-md px-4 mb-4 outline-none focus:border-teal-700"
            />
            <input
              id="email"
              type="email"
              placeholder="Email"
              className="w-full h-12 border cursor-none bg-transparent text-gray-500  rounded-md px-4 mb-4 outline-none focus:border-teal-700"
            />
            <textarea
              id="message"
              placeholder="Your Message"
              className="w-full h-32 border cursor-none bg-transparent rounded-md py-4 px-4 text-gray-500  outline-none focus:border-teal-700"
            />
          </div>

          <button
            type="button"
            className="bg-[#009CD9] cursor-none text-white  py-3 px-6 rounded-md custom-shadow mb-8 text-xl w-full transition-all duration-300 hover:bg-teal-600"
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
