import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#009CD9] text-white py-8">
      <div className="container mx-auto text-center">
        <p className="text-lg font-bold mb-4">Stay Connected</p>
        <div className="flex justify-center mb-4">
          <a href="#" className="mr-4 hover:text-gray-400">Twitter</a>
          <a href="#" className="mr-4 hover:text-gray-400">Facebook</a>
          <a href="#" className="mr-4 hover:text-gray-400">Instagram</a>
          <a href="#" className="hover:text-gray-400">LinkedIn</a>
        </div>
        <p>&copy; {new Date().getFullYear()} Flutteropia. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
