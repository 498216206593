// src/routes/SignUpSignInPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebase'; // Assuming Firebase setup in a separate file

const SignUpSignInPage = () => {
  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful login
        console.log('User signed in:', result.user);
      })
      .catch((error) => {
        // Handle errors
        console.error('Login error:', error);
      });
  };

  const handleSignup = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful signup
        console.log('User signed up:', result.user);
      })
      .catch((error) => {
        // Handle errors
        console.error('Signup error:', error);
      });
  };

  return (
    <div className="SignUpSignInPage">
      <h1>Sign Up or Sign In</h1>
      <button className="bg-transparent py-2 px-4 rounded font-medium text-black" onClick={handleLogin}>
        LOGIN WITH GOOGLE
      </button>
      <button className="border border-black rounded px-4 py-2 font-medium text-black" onClick={handleSignup}>
        SIGNUP WITH GOOGLE
      </button>
      <p>
        Already have an account? <Link to="/signin">Sign In</Link>
      </p>
    </div>
  );
};

export default SignUpSignInPage;
