import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './Routes/landingpage';
import SignUpSignInPage from './Routes/SignUpSignInPage';
import CoursesPage from './Routes/CoursesPage';
import CoursePage from './Routes/CoursePage';
import CompletionPage from './Routes/CompletionPage';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<SignUpSignInPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/courses/:courseId" element={<CoursePage />} />
          <Route path="/completion" element={<CompletionPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
