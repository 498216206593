import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const CompletionPage = () => {
  const navigate = useNavigate();

  const shareCompletion = () => {
    const shareData = {
      title: 'Course Completion From Flutteropia',
      text: 'I have successfully completed a Flutter course! Take a look at this amazing page.',
      url: 'https://flutteropia.xyz'
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Course shared successfully'))
        .catch((error) => console.error('Error sharing course:', error));
    } else {
      alert('Sharing is not supported on this browser. Please copy the link to share.');
    }
  };

  const downloadCertificate = () => {
    // Generate certificate content
    const certificateContent = generateCertificateContent();

    // Create a Blob from the certificate content
    const blob = new Blob([certificateContent], { type: 'text/html' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'certificate.html'; // Change the file name and extension as needed

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link to trigger download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const generateCertificateContent = () => {
    // Example certificate data (replace with your actual data logic)
    const certificateData = {
      learnerName: 'John Doe',
      courseTitle: 'Advanced Flutter Development',
      instructor: 'Flutteropia',
      completionDate: new Date().toLocaleDateString(),
    };
  
    // Certificate HTML structure with advanced styling
    const certificateHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificate of Completion</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f0f0f0;
          }
          .certificate {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 20px;
          }
          .certificate-content {
            text-align: center;
          }
          .certificate-title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .certificate-details {
            font-size: 18px;
            margin-bottom: 10px;
          }
          .certificate-message {
            font-size: 16px;
            margin-top: 20px;
          }
        </style>
      </head>
      <body>
        <div class="certificate justify-center mt-40">
          <div class="certificate-content">
            <h1 class="certificate-title">Certificate of Completion</h1>
            <p class="certificate-details">This is to certify that</p>
            <h2>${certificateData.learnerName}</h2>
            <p class="certificate-details">has successfully completed the course</p>
            <h3>${certificateData.courseTitle}</h3>
            <p class="certificate-details">taught by ${certificateData.instructor}</p>
            <p class="certificate-details">on ${certificateData.completionDate}</p>
            <p class="certificate-message">Congratulations!</p>
          </div>
        </div>
      </body>
      </html>
    `;
  
    return certificateHtml;
  };

  return (
    <div className="min-h-screen font-poppins bg-gray-100">
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden p-6 text-center">
          <h1 className="text-3xl font-semibold mb-4">Congratulations!</h1>
          {/* Course details */}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Course Summary</h2>
            <p className="text-gray-700 mb-2">Course Title: Advanced Flutter Development</p>
            <p className="text-gray-700 mb-2">Instructor: Flutteropia</p>
            <p className="text-gray-700 mb-2">Duration: 40 hours</p>
            <p className="text-gray-700 mb-2">Completed on: {new Date().toLocaleDateString()}</p>
          </div>
          {/* Share button */}
          <div className="flex gap-4">
  {/* Share button */}
  <button
    onClick={shareCompletion}
    className="flex-1 bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md transition duration-300"
  >
    Share Your Achievement
  </button>
  {/* Download certificate button */}
  <button
    onClick={downloadCertificate}
    className="flex-1 bg-blue-500 hover:bg-blue-600 text-white px-16 py-2 rounded-md transition duration-300"
  >
    Download Certificate
  </button>
  {/* Back to courses button */}
  <Link to="/courses" className="flex-1">
    <button className="bg-blue-500 hover:bg-blue-600 text-white px-16 py-2 rounded-md transition duration-300 w-full">
      Back to Courses
    </button>
  </Link>
</div>

        </div>
        {/* Add margin between buttons */}
        <style>{`
          .bg-white > button:not(:last-child) {
            margin-bottom: 10px;
          }
        `}</style>
      </div>
    </div>
  );
};

export default CompletionPage;
